var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-1/5 medium:w-1/3 mobile:w-full flex-col items-center p-1 duration-75"},[_c('div',{staticClass:"file-container w-full text-darkgrey flex flex-row flex-wrap rounded-xl items-center mobile:py-2 mobile:px-2 duration-100 shadow bg-offwhite hover:bg-darkgreen hover:text-white h-full\ncursor-pointer hover:shadow-outline",on:{"click":function($event){return _vm.toggleGroup(_vm.category)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"p-3 flex items-center w-full"},[_c('div',{staticClass:"flex flex-col w-3/12"},[_c('img',{staticClass:"h-10 w-10",class:_vm.isHovered ? 'filter-to-white' : '',attrs:{"src":"/new/folder.svg"}})]),_c('div',{staticClass:"flex flex-col w-9/12"},[_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.category.name)+" ")]),(
              _vm.category.related_object && _vm.category.related_object === 'Project'
            )?_c('span',{staticClass:"text-sm"},[_vm._v(" Projekt ")]):_vm._e(),(
              _vm.category.related_object &&
                _vm.category.related_object.substring(0, 4) === 'Todo'
            )?_c('span',{staticClass:"text-sm"},[_vm._v(" Märkus ")]):_vm._e(),(
              _vm.category.related_object && _vm.category.related_object === 'Tasks'
            )?_c('span',{staticClass:"text-sm"},[_vm._v(" Ülesanded ")]):_vm._e(),(_vm.category.related_object && _vm.category.related_object === 'Task')?_c('span',{staticClass:"text-sm"},[_vm._v(" Ülesanne ")]):_vm._e(),(
              _vm.category.related_object && _vm.category.related_object === 'Workers'
            )?_c('span',{staticClass:"text-sm"},[_vm._v(" Töötajad ")]):_vm._e(),(!_vm.category.related_object)?_c('span',{staticClass:"text-sm"},[_vm._v(" Kaust ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }