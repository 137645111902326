<template>
  <div class="modal">
    <div class="modal-body modal-small shadow-outline">
      <div class="w-full mx-auto flex flex-col modal-content">
        <h3 class="mb-3 self-center">Uus kaust</h3>
        <h4 class="mb-1">Nimi</h4>
        <div class="flex rounded-md text-justify mb-3 w-full">
          <input
            type="text"
            v-model="categoryName"
            class="flex-grow mobile:mr-0 shadow bg-offwhite focus:shadow-outline mobile:mb-3"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div class="flex justify-between w-full">
          <button @click="confirm()" class="new-button-green">
            <span class="icon typcn typcn-tick"></span>
            <span class="label">Salvesta</span>
          </button>
          <button @click="closeModal()" class="new-button-danger">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Tühista</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateFolderModal",
  data() {
    return {
      categoryName: ""
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.categoryName);
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
