<template>
  <div
    class="flex w-1/5 medium:w-1/3 mobile:w-full flex-col items-center p-1 duration-75"
  >
    <div
      class="file-container w-full text-darkgrey flex flex-row flex-wrap rounded-xl items-center mobile:py-2 mobile:px-2 duration-100 shadow bg-offwhite hover:bg-darkgreen hover:text-white h-full
cursor-pointer hover:shadow-outline"
      @click="toggleGroup(category)"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div class="p-3 flex items-center w-full">
        <div class="flex flex-col w-3/12">
          <img
            src="/new/folder.svg"
            class="h-10 w-10"
            :class="isHovered ? 'filter-to-white' : ''"
          />
        </div>
        <div class="flex flex-col w-9/12">
          <span class="font-medium">
            {{ category.name }}
          </span>
          <span
            v-if="
              category.related_object && category.related_object === 'Project'
            "
            class="text-sm"
          >
            Projekt
          </span>
          <span
            v-if="
              category.related_object &&
                category.related_object.substring(0, 4) === 'Todo'
            "
            class="text-sm"
          >
            Märkus
          </span>
          <span
            v-if="
              category.related_object && category.related_object === 'Tasks'
            "
            class="text-sm"
          >
            Ülesanded
          </span>
          <span
            v-if="category.related_object && category.related_object === 'Task'"
            class="text-sm"
          >
            Ülesanne
          </span>
          <span
            v-if="
              category.related_object && category.related_object === 'Workers'
            "
            class="text-sm"
          >
            Töötajad
          </span>
          <span v-if="!category.related_object" class="text-sm">
            Kaust
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileCategory",
  props: {
    category: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isHovered: false
    };
  },
  methods: {
    toggleGroup() {
      this.$emit("toggleGroup", this.category);
    }
  }
};
</script>
