<template>
  <files></files>
</template>

<script>
import Files from "@/components/company/Files";
export default {
  components: {
    Files
  }
};
</script>
