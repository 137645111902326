<template>
  <div class="modal">
    <div class="modal-body modal-small px-8 pb-5 pt-3 shadow-outline">
      <div class="w-full mx-auto flex flex-col items-center">
        <h3 class="mb-3">Kinnitus</h3>
        <div class="flex bg-white rounded-md p-3 mb-3">
          <span class="mobile:w-full"
            >Kas te olete kindel, et Te soovite kausta kustutada?</span
          >
        </div>
        <div class="flex flex-row py-3">
          <span>Kustuta kõik failid kaustas?</span>
          <input
            type="checkbox"
            v-model="deleteFiles"
            class="h-6 w-6 mr-3 pr-3 ml-3 shadow-none focus:shadow-outlineLarge mobile:focus:shadow-none p-3"
          />
        </div>
        <div class="flex justify-evenly w-6/12 mobile:w-full">
          <button @click="confirm()" class="new-button-green">
            <span class="icon typcn typcn-tick"></span>
            <span class="label">Kinnita</span>
          </button>
          <button @click="closeModal()" class="new-button-danger">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Katkesta</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteFolderModal",
  data() {
    return {
      deleteFiles: false
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.deleteFiles);
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>
