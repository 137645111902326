<template>
  <div class="flex mobile:relative">
    <div
      class="mx-auto bg-white duration-100 justify-start flex flex-col w-full mobile:w-full px-5 py-3"
    >
      <h2 class="pt-3">Dokumendid</h2>
      <div
        class="mx-1 flex flex-row mobile:flex-col items-center bg-white mb-2"
      >
        <div class="flex flex-row items-center w-8/12 mobile:w-full">
          <h4 @click="backButton" class="cursor-pointer flex">
            {{ $store.state.companyData.activeCompany.company_name }}
          </h4>

          <div
            v-if="activeFileGroup && categoriesLoaded"
            class="flex items-center"
          >
            <div
              v-for="item in getParentCategories(activeFileGroup)"
              :key="item.id"
              class="flex flex-row items-center"
            >
              <span
                class="typcn typcn-chevron-right text leading-none px-1"
              ></span>
              <h4
                :class="activeFileGroup !== item ? 'cursor-pointer' : ''"
                @click="activeFileGroup !== item ? toggleCategory(item) : ''"
              >
                {{ item.name }}
              </h4>
            </div>
            <div
              v-if="
                ['R0', 'R1', 'R2'].includes(
                  this.$store.state.companyData.activeCompany.worker_permission
                )
              "
              class="relative"
              @click="showFileGroupMenu = !showFileGroupMenu"
            >
              <span
                class="typcn typcn-arrow-sorted-down text-xl cursor-pointer transform duration-100"
                :class="showFileGroupMenu ? 'text-green rotate-180' : ''"
              ></span>
              <div class="file-group-menu" v-if="showFileGroupMenu">
                <!--
                <div class="item rounded-t-md">Muuda nime</div>
                -->
                <div
                  class="item rounded-md hover:bg-danger hover:text-white cursor-pointer"
                  @click="deleteFolderModalActive = true"
                >
                  <span class="typcn typcn-delete"></span>
                  <span>Kustuta kaust</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-4/12 mobile:w-full">
          <div
            class="bg-offwhite rounded-gwdfull px-2 py-1 shadow flex items-center"
          >
            <div class="w-1/12 justify-center flex">
              <span class="">
                <img src="/bc21/search.svg" class="h-4 w-4" />
              </span>
            </div>
            <div class="w-10/12">
              <input
                class="w-full shadow-none focus:shadow-none h-full bg-offwhite"
                placeholder="Otsi"
                v-model.lazy="nameFilter"
                @change="loadCompanyFiles"
              />
            </div>
            <div
              class="w-1/12 flex items-center justify-center"
              v-if="nameFilter && nameFilter.length > 0"
            >
              <div
                class="flex bg-danger rounded-md py-1 px-2 cursor-pointer hover:bg-dangerdark shadow"
                @click="disableFilter"
              >
                <span class="typcn typcn-times text-white"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mx-1 rounded-gwdfull py-1 mb-2 flex-col">
        <div class="flex items-center mb-1">
          <h3 class="mr-3">Kaustad</h3>
          <button
            @click="createFolderModalActive = true"
            class="alt-button-green"
            v-if="
              ['R0', 'R1', 'R2'].includes(
                $store.state.companyData.activeCompany.worker_permission
              )
            "
          >
            <span class="typcn typcn-plus icon"></span>
            <span class="label">Lisa kaust</span>
          </button>
        </div>
        <div class="h-1 bg-offwhite rounded-gwdfull w-full"></div>
      </div>
      <div
        class="flex flex-row flex-wrap mb-2"
        v-if="activeFileGroup && categoriesLoaded"
      >
        <file-category
          v-for="category in activeFileGroup.subcategories.slice(0, folderCap)"
          :key="category.id"
          :category="category"
          @toggleGroup="toggleCategory"
          :class="existingGroups.length > 4 ? 'mb-3' : ''"
        />
        <div
          class="w-full flex justify-center"
          v-if="activeFileGroup.subcategories.length > 10"
        >
          <button @click="toggleFolders">Näita kõiki</button>
        </div>
      </div>
      <div class="flex flex-row flex-wrap mb-2" v-if="!activeFileGroup">
        <file-category
          v-for="category in existingGroups
            .filter(grp => grp !== '')
            .slice(0, folderCap)"
          :key="category.id"
          :category="category"
          @toggleGroup="toggleCategory"
          :class="existingGroups.length > 4 ? 'mb-3' : ''"
        />
        <div
          class="w-full flex justify-center"
          v-if="existingGroups.length > 10"
        >
          <button
            class=""
            :class="folderCap < 9999 ? 'new-button-green' : 'new-button-danger'"
            @click="toggleFolders"
          >
            <span
              class="icon typcn typcn-arrow-sorted-down transform duration-100"
              :class="folderCap < 9999 ? '' : 'rotate-180'"
            ></span>
            <span class="label">{{
              folderCap === 9999 ? "Näita vähem" : "Näita kõiki"
            }}</span>
          </button>
        </div>
      </div>
      <div v-if="filesLoaded" class="mb-1">
        <div
          class="flex flex-col mx-1 items-center rounded-gwdfull mb-2 duration-75"
          :class="uploadWidgetActive ? 'py-5' : 'py-1'"
        >
          <div class="flex w-full items-center mb-1">
            <h3 class="mr-3">Failid</h3>
            <button
              v-if="
                ['R0', 'R1', 'R2', 'R3'].includes(
                  $store.state.companyData.activeCompany.worker_permission
                ) && !uploadWidgetActive
              "
              @click="uploadModalActive = true"
              class="alt-button-green"
            >
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa</span>
            </button>
          </div>
          <div class="w-full h-1 bg-offwhite rounded-gwdfull mb-2"></div>
        </div>
        <div
          v-if="company_files.results.length > 0"
          class="flex flex-row flex-wrap"
        >
          <project-file
            v-for="file in company_files.results"
            :key="file.id"
            :company="$store.state.companyData.activeCompany.uuid"
            :file="file"
            :user-role="
              $store.state.companyData.activeCompany.worker_permission
            "
            @reloadFiles="loadCompanyFiles"
            @expandFile="openSidebar"
            @closeFile="closeSidebar"
            :current-expanded="expandedFile ? expandedFile.id : null"
          >
          </project-file>
        </div>
        <paginator
          v-if="company_files.count > 1"
          :page="page"
          :count="company_files.count"
          @pageChange="pageChange"
        ></paginator>
        <div v-if="company_files.results.length === 0" class="mb-3">
          <span>
            Kaustas puuduvad failid.
          </span>
        </div>
      </div>
      <create-folder-modal
        v-if="createFolderModalActive"
        @closeModal="createFolderModalActive = false"
        @confirm="saveNewCategory"
      ></create-folder-modal>
      <delete-folder-modal
        v-if="deleteFolderModalActive"
        @closeModal="deleteFolderModalActive = false"
        @confirm="deleteFolder"
      >
      </delete-folder-modal>
    </div>
    <file-modal
      v-if="$store.state.fileViewer.displayFileModal"
      :file="$store.state.fileViewer.fileToDisplay"
      :company="$store.state.fileViewer.company"
      :type="'file'"
      @fileDeleted="loadCompanyFiles"
    ></file-modal>
    <upload-file-modal
      v-if="uploadModalActive"
      :active-file-group="activeFileGroup"
      @closeModal="uploadModalActive = false"
      @filesUpdated="loadCompanyFiles"
    ></upload-file-modal>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import FileCategory from "@/components/files/FileCategory";
import ProjectFile from "@/components/project/ProjectFile";
import CreateFolderModal from "@/components/files/CreateFolderModal";
import DeleteFolderModal from "@/components/files/DeleteFolderModal";
import Paginator from "@/components/reusable/Paginator";
import FileModal from "@/components/reusable/FileModal";
import UploadFileModal from "@/components/reusable/UploadFileModal";
export default {
  name: "CompanyFiles",
  components: {
    FileModal,
    Paginator,
    DeleteFolderModal,
    CreateFolderModal,
    ProjectFile,
    FileCategory,
    UploadFileModal
  },
  mixins: [RequestHandler],
  data() {
    return {
      company_files: [],
      uploadWidgetActive: false,
      filesLoaded: false,
      activeFileGroup: null,
      addFileModalActive: false,
      existingGroups: [],
      existingGroupArray: [],
      nameFilter: null,
      uploadedFileSize: 0,
      fileSizeWarning: false,
      remainingSizeNormalized: "",
      remainingSize: 0,
      usedSize: 0,
      createFolderModalActive: false,
      deleteFolderModalActive: false,
      sidebarActive: false,
      expandedFile: null,
      showFileGroupMenu: false,
      filesToSave: [],
      page: 1,
      folderCap: 5,
      activeImage: null,
      categoriesLoaded: false,
      uploadModalActive: false
    };
  },
  props: {
    company: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    getParentCategories(category) {
      if (!category.parent_id) {
        return [
          {
            id: category.id,
            name: category.name
          }
        ];
      } else if (Object.keys(category.parent_id).length > 0) {
        return this.getParentCategories(category.parent_id).concat([
          {
            id: category.id,
            name: category.name
          }
        ]);
      }
    },
    pageChange(e) {
      this.page = e;
      this.loadCompanyFiles();
    },
    toggleCategory(category) {
      this.nameFilter = "";
      if (this.activeFileGroup && this.activeFileGroup.id === category) {
        this.activeFileGroup = null;
        this.loadCompanyFileCategories(null);
      } else {
        if (category) {
          this.activeFileGroup = category;
          this.loadCompanyFileCategories(category);
        } else {
          this.loadCompanyFileCategories(null);
        }
      }
    },
    deleteFolder(delete_files) {
      if (this.activeFileGroup !== null) {
        this.$store.state.loading = true;
        let url =
          "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/categories/" +
          this.activeFileGroup.id +
          "/delete/";
        if (delete_files) {
          url = url += "?delete_files=true";
        }
        this.apiRequest(url, "delete", true).then(res => {
          if (res.status === 200) {
            this.activeFileGroup = null;
            this.loadCompanyFileCategories(null);
            this.$store.state.loading = false;
          } else if ({ res }.res.response.status === 403) {
            this.$store.state.loading = false;
          }
        });
      }
    },
    disableFilter() {
      this.nameFilter = "";
      this.loadCompanyFiles();
    },
    backButton() {
      this.activeFileGroup = null;
      this.loadCompanyFileCategories(null);
    },
    calculateUploadSize() {
      if (
        this.$refs.companyFiles.files.length > 0 ||
        this.$refs.companyImages.files.length > 0
      ) {
        let num = 0;
        this.fileSizeWarning = false;
        for (let i = 0; i < this.$refs.companyFiles.files.length; i++) {
          const file = this.$refs.companyFiles.files[i];
          num += file.size;
        }
        if (num > this.remainingSize) {
          this.fileSizeWarning = true;
        }
        this.uploadedFileSize = num;
        this.initSpaceChart();
      }
    },
    saveNewCategory(category_name) {
      let data = {
        name: category_name
      };
      if (this.activeFileGroup) {
        data.category = this.activeFileGroup.id;
      }
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/files/categories/add/",
        "post",
        true,
        data
      ).then(res => {
        if (res.status === 201) {
          this.loadCompanyFileCategories(this.activeFileGroup);
          this.$store.state.loading = false;
        } else if ({ res }.res.response.status === 403) {
          this.$store.state.loading = false;
        }
      });
    },
    loadCompanyFileCategories(category) {
      this.categoriesLoaded = false;
      this.existingGroups = [];
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/categories/" +
          (category ? "?category_id=" + category.id : ""),
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          if (category) {
            this.activeFileGroup = res.data[0];
          } else {
            this.existingGroups = res.data;
          }
          this.loadCompanyFiles();
          this.categoriesLoaded = true;
          this.$store.state.loading = false;
        } else if ({ res }.res.response.status === 403) {
          this.$store.state.loading = false;
        }
      });
    },
    loadCompanyFiles() {
      let url =
        "company/" +
        this.$store.state.companyData.activeCompany.uuid +
        "/files/";
      this.company_files = [];
      this.filesLoaded = false;
      let first = true;
      if (this.activeFileGroup !== null) {
        if (first) {
          url = url.concat("?category=" + this.activeFileGroup.id);
          first = false;
        } else {
          url = url.concat("&category=" + this.activeFileGroup.id);
        }
      }
      if (first) {
        url = url + "?page=" + this.page;
        first = false;
      } else {
        url = url + "&page=" + this.page;
      }
      if (this.nameFilter) {
        if (first) {
          url = url.concat("?name=" + this.nameFilter + "&all_folders=true");
        } else {
          url = url.concat("&name=" + this.nameFilter + "&all_folders=true");
        }
      }
      this.apiRequest(url, "get", true).then(res => {
        if (res.status === 200) {
          this.$store.state.loading = false;
          this.company_files = res.data;
          this.filesLoaded = true;
        } else if ({ res }.res.response.status === 403) {
          this.$store.state.loading = false;
        }
      });
    },
    addFilesToCompany() {
      this.$store.state.loading = true;
      if (this.filesToSave.length > 0) {
        const filesToUpload = new FormData();
        for (let i = 0; i < this.filesToSave.length; i++) {
          const file = this.filesToSave[i];
          const fileToAppend = new File([file], file.name, { type: file.type });
          filesToUpload.append("files", fileToAppend, file.name);
        }
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/files/add/",
          "post",
          true,
          filesToUpload
        )
          .then(res => {
            if (res.status === 201) {
              if (this.activeFileGroup) {
                let files = res.data;
                let data = [];
                for (let i = 0; i < files.length; i++) {
                  data.push({
                    id: files[i],
                    category: this.activeFileGroup.id
                  });
                }
                this.apiRequest(
                  "company/" +
                    this.$store.state.companyData.activeCompany.uuid +
                    "/files/edit/multiple/",
                  "patch",
                  true,
                  data
                ).then(res2 => {
                  if (res2) {
                    this.loadCompanyFiles();
                  }
                });
              } else {
                this.loadCompanyFiles();
              }
              this.uploadWidgetActive = false;
              this.filesToSave = [];
              this.$store.state.loading = false;
            }
          })
          .catch(error => {
            this.$store.state.loading = false;
            const errObj = { error };
            if (errObj.error.response.status === 413) {
              window.scrollTo(0, 0);
            }
          });
      } else {
        this.$store.state.loading = false;
      }
    },
    openSidebar(e) {
      this.closeSidebar();
      this.sidebarActive = true;
      this.expandedFile = e;
    },
    toggleFolders() {
      if (this.folderCap < 9999) {
        this.folderCap = 9999;
      } else {
        if (window.innerWidth < 992) {
          this.folderCap = 5;
        } else {
          this.folderCap = 10;
        }
      }
    },
    closeSidebar() {
      this.sidebarActive = false;
      this.expandedFile = null;
    },
    toggleUploadWidget() {
      this.uploadWidgetActive = true;
      setTimeout(() => {
        this.initSpaceChart();
      }, 300);
    },
    handleFileUpdate(e) {
      this.filesToSave = e;
    },
    clearFolder() {
      this.activeFileGroup = null;
      this.sidebarActive = false;
    }
  },
  mounted() {
    this.loadCompanyFileCategories(null);
    if (window.innerWidth < 992) {
      this.folderCap = 5;
    } else {
      this.folderCap = 10;
    }
  },
  computed: {
    totalSize() {
      return this.remainingSize + this.usedSize;
    },
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    }
  },
  watch: {
    uploadWidgetActive() {
      if (this.uploadWidgetActive === false) {
        this.filesToSave = [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.file-group-menu {
  @apply absolute bg-white rounded-md shadow text-black;
  top: 100%;
  left: -6rem;
  width: 12rem;
  .item {
    @apply px-2 py-1 text-base flex justify-around;
  }
}
.overbudget {
  @apply bg-danger rounded-r-md;
}
</style>
