var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mobile:relative"},[_c('div',{staticClass:"mx-auto bg-white duration-100 justify-start flex flex-col w-full mobile:w-full px-5 py-3"},[_c('h2',{staticClass:"pt-3"},[_vm._v("Dokumendid")]),_c('div',{staticClass:"mx-1 flex flex-row mobile:flex-col items-center bg-white mb-2"},[_c('div',{staticClass:"flex flex-row items-center w-8/12 mobile:w-full"},[_c('h4',{staticClass:"cursor-pointer flex",on:{"click":_vm.backButton}},[_vm._v(" "+_vm._s(_vm.$store.state.companyData.activeCompany.company_name)+" ")]),(_vm.activeFileGroup && _vm.categoriesLoaded)?_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.getParentCategories(_vm.activeFileGroup)),function(item){return _c('div',{key:item.id,staticClass:"flex flex-row items-center"},[_c('span',{staticClass:"typcn typcn-chevron-right text leading-none px-1"}),_c('h4',{class:_vm.activeFileGroup !== item ? 'cursor-pointer' : '',on:{"click":function($event){_vm.activeFileGroup !== item ? _vm.toggleCategory(item) : ''}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),(
              ['R0', 'R1', 'R2'].includes(
                this.$store.state.companyData.activeCompany.worker_permission
              )
            )?_c('div',{staticClass:"relative",on:{"click":function($event){_vm.showFileGroupMenu = !_vm.showFileGroupMenu}}},[_c('span',{staticClass:"typcn typcn-arrow-sorted-down text-xl cursor-pointer transform duration-100",class:_vm.showFileGroupMenu ? 'text-green rotate-180' : ''}),(_vm.showFileGroupMenu)?_c('div',{staticClass:"file-group-menu"},[_c('div',{staticClass:"item rounded-md hover:bg-danger hover:text-white cursor-pointer",on:{"click":function($event){_vm.deleteFolderModalActive = true}}},[_c('span',{staticClass:"typcn typcn-delete"}),_c('span',[_vm._v("Kustuta kaust")])])]):_vm._e()]):_vm._e()],2):_vm._e()]),_c('div',{staticClass:"w-4/12 mobile:w-full"},[_c('div',{staticClass:"bg-offwhite rounded-gwdfull px-2 py-1 shadow flex items-center"},[_vm._m(0),_c('div',{staticClass:"w-10/12"},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.nameFilter),expression:"nameFilter",modifiers:{"lazy":true}}],staticClass:"w-full shadow-none focus:shadow-none h-full bg-offwhite",attrs:{"placeholder":"Otsi"},domProps:{"value":(_vm.nameFilter)},on:{"change":[function($event){_vm.nameFilter=$event.target.value},_vm.loadCompanyFiles]}})]),(_vm.nameFilter && _vm.nameFilter.length > 0)?_c('div',{staticClass:"w-1/12 flex items-center justify-center"},[_c('div',{staticClass:"flex bg-danger rounded-md py-1 px-2 cursor-pointer hover:bg-dangerdark shadow",on:{"click":_vm.disableFilter}},[_c('span',{staticClass:"typcn typcn-times text-white"})])]):_vm._e()])])]),_c('div',{staticClass:"flex mx-1 rounded-gwdfull py-1 mb-2 flex-col"},[_c('div',{staticClass:"flex items-center mb-1"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Kaustad")]),(
            ['R0', 'R1', 'R2'].includes(
              _vm.$store.state.companyData.activeCompany.worker_permission
            )
          )?_c('button',{staticClass:"alt-button-green",on:{"click":function($event){_vm.createFolderModalActive = true}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa kaust")])]):_vm._e()]),_c('div',{staticClass:"h-1 bg-offwhite rounded-gwdfull w-full"})]),(_vm.activeFileGroup && _vm.categoriesLoaded)?_c('div',{staticClass:"flex flex-row flex-wrap mb-2"},[_vm._l((_vm.activeFileGroup.subcategories.slice(0, _vm.folderCap)),function(category){return _c('file-category',{key:category.id,class:_vm.existingGroups.length > 4 ? 'mb-3' : '',attrs:{"category":category},on:{"toggleGroup":_vm.toggleCategory}})}),(_vm.activeFileGroup.subcategories.length > 10)?_c('div',{staticClass:"w-full flex justify-center"},[_c('button',{on:{"click":_vm.toggleFolders}},[_vm._v("Näita kõiki")])]):_vm._e()],2):_vm._e(),(!_vm.activeFileGroup)?_c('div',{staticClass:"flex flex-row flex-wrap mb-2"},[_vm._l((_vm.existingGroups
          .filter(function (grp) { return grp !== ''; })
          .slice(0, _vm.folderCap)),function(category){return _c('file-category',{key:category.id,class:_vm.existingGroups.length > 4 ? 'mb-3' : '',attrs:{"category":category},on:{"toggleGroup":_vm.toggleCategory}})}),(_vm.existingGroups.length > 10)?_c('div',{staticClass:"w-full flex justify-center"},[_c('button',{class:_vm.folderCap < 9999 ? 'new-button-green' : 'new-button-danger',on:{"click":_vm.toggleFolders}},[_c('span',{staticClass:"icon typcn typcn-arrow-sorted-down transform duration-100",class:_vm.folderCap < 9999 ? '' : 'rotate-180'}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.folderCap === 9999 ? "Näita vähem" : "Näita kõiki"))])])]):_vm._e()],2):_vm._e(),(_vm.filesLoaded)?_c('div',{staticClass:"mb-1"},[_c('div',{staticClass:"flex flex-col mx-1 items-center rounded-gwdfull mb-2 duration-75",class:_vm.uploadWidgetActive ? 'py-5' : 'py-1'},[_c('div',{staticClass:"flex w-full items-center mb-1"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Failid")]),(
              ['R0', 'R1', 'R2', 'R3'].includes(
                _vm.$store.state.companyData.activeCompany.worker_permission
              ) && !_vm.uploadWidgetActive
            )?_c('button',{staticClass:"alt-button-green",on:{"click":function($event){_vm.uploadModalActive = true}}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Lisa")])]):_vm._e()]),_c('div',{staticClass:"w-full h-1 bg-offwhite rounded-gwdfull mb-2"})]),(_vm.company_files.results.length > 0)?_c('div',{staticClass:"flex flex-row flex-wrap"},_vm._l((_vm.company_files.results),function(file){return _c('project-file',{key:file.id,attrs:{"company":_vm.$store.state.companyData.activeCompany.uuid,"file":file,"user-role":_vm.$store.state.companyData.activeCompany.worker_permission,"current-expanded":_vm.expandedFile ? _vm.expandedFile.id : null},on:{"reloadFiles":_vm.loadCompanyFiles,"expandFile":_vm.openSidebar,"closeFile":_vm.closeSidebar}})}),1):_vm._e(),(_vm.company_files.count > 1)?_c('paginator',{attrs:{"page":_vm.page,"count":_vm.company_files.count},on:{"pageChange":_vm.pageChange}}):_vm._e(),(_vm.company_files.results.length === 0)?_c('div',{staticClass:"mb-3"},[_c('span',[_vm._v(" Kaustas puuduvad failid. ")])]):_vm._e()],1):_vm._e(),(_vm.createFolderModalActive)?_c('create-folder-modal',{on:{"closeModal":function($event){_vm.createFolderModalActive = false},"confirm":_vm.saveNewCategory}}):_vm._e(),(_vm.deleteFolderModalActive)?_c('delete-folder-modal',{on:{"closeModal":function($event){_vm.deleteFolderModalActive = false},"confirm":_vm.deleteFolder}}):_vm._e()],1),(_vm.$store.state.fileViewer.displayFileModal)?_c('file-modal',{attrs:{"file":_vm.$store.state.fileViewer.fileToDisplay,"company":_vm.$store.state.fileViewer.company,"type":'file'},on:{"fileDeleted":_vm.loadCompanyFiles}}):_vm._e(),(_vm.uploadModalActive)?_c('upload-file-modal',{attrs:{"active-file-group":_vm.activeFileGroup},on:{"closeModal":function($event){_vm.uploadModalActive = false},"filesUpdated":_vm.loadCompanyFiles}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-1/12 justify-center flex"},[_c('span',{},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":"/bc21/search.svg"}})])])}]

export { render, staticRenderFns }